@import '../../global.scss';
.porfolio{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1{
        font-size:50px;

        @include mobile{
            font-size:20px;
        }
    }
    ul{
        margin: 10px;
        padding: 0;
        list-style: none;
        display: flex;

        @include mobile{
            flex-wrap:wrap;
            justify-content: center;
        }
        // li{
        //     font-size:14px;
        //     margin-right: 50px;
        //     padding:7px;
        //     border-radius: 10px;
        //     cursor:pointer;
        //     &.active{
        //         background-color: $mainColor;
        //         color:white;
        //     }
        // }
    }
    .container{
        width:70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        // animation: flyinleft 2s;

        @include mobile{
            width:100%;
        }
        
        .item{
            width: 320px;
            height: 180px;
            border-radius: 20px;
            border: 1px solid rgb(240, 239, 239);
            margin:10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color:white;
            position: relative;
            box-shadow: 0px 0px 15px -8px black;            
            @include mobile{
                width: 160px;
                height: 90px;
            }
            h3{
                position: absolute;
                font-size: 20px;
                cursor: pointer;

                @include mobile{
                    font-size:12px;
                }
                a{
                    color:inherit;
                    text-decoration: none;
                }
            }
            img{
                width:100%;
                height:100%;
                object-fit: cover;
                z-index:1;
            }
            &:hover{
                transition: all 1.5s ease;
                background-color: $mainColor;
                img{
                    opacity: 0.2;
                    z-index: 0;
                }
            }
        }
        // @keyframes flyinleft {
        //     0%{
        //         margin-left:200px;
        //         opacity: 0;
        //     }
        //     100%{
        //         margin-left:0px;
        //         opacity: 1; 
        //     }
        // }
       
    }
}