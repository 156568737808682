@import '../../global.scss';
.goal{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    @include mobile{
    }

    .left{
        width:100%;
        height:80vh;
        flex:5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin:auto;
        .title{
            display: flex;
            justify-content: center;
            align-items: center;
            flex:5;
            font-size:50px;
            @include mobile{
                font-size:30px;
            }
            .titleFont{
                @include mobile{
                    margin-left: 20px;
                }
            }
        }
        .imgContainer1{
            flex:5;
            width:500px;
            display: flex;
            justify-content: center;
            align-items: center;
            object-fit: cover;
            @include mobile{
                width:90%;
                align-items: flex-start;
                overflow-y: hidden;
            }
            img{
                width:500px;
                height:300px;
                @include mobile{
                    width:100%;
                    height:300px;
                }
            }
        }

    }
    .right{
        width:100%;
        flex:5;
        height:80vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin:auto;
        .content{
            flex:5;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding:0 40px;
            font-size:20px;
            @include mobile{
                font-size: 12px;
                padding:5px 15px;
            }
            .contents{
                margin-top:30px;
                @include mobile{
                    margin-top: 10px;
                }
            }
        }
        .imgContainer{
            flex:5;
            width:90%;
            display: flex;
            justify-content: center;
            align-items: center;
            object-fit: cover;
            @include mobile{
                width:90%;
                align-items: flex-start;
                overflow-y: hidden;
            }
            img{
                width:100%;
                height:300px;
            }
        }
    }
}