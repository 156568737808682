@import '../../global.scss';
.works{
    background-color: crimson;
    display:flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .arrow{
        height: 50px;
        position:absolute;
        font-size:150px;
        color:white;

        @include mobile{
            display: none;
        }
        &.left{
            right:30px;
        }
        &.right{
            left: 30px;
        }
    }

    .slider{
        height: 400px;
        display: flex;
        position: absolute;
        left:0;
        transition: all 1s ease-out;

        @include mobile{
            height: 100vh;
            flex-direction: column;
            justify-content: center;
        }
        .container{
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;

            .item{
                width: 700px;
                height:100%;
                background-color: white;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                
                @include mobile{
                    width: 90%;
                    height: 150px;
                    margin: 15px 0;  
                }

                .left{
                    flex: 4;
                    height: 80%;
                    // background-color: green;
                    display:flex;
                    justify-content: center;
                    align-content: center;

                    .leftContainer{
                        width: 90%;
                        height:70%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        @include mobile{
                            
                            height: 100%;
                        }

                        .imgContainer{
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: rgb(245, 179, 155);
                            display: flex;
                            align-content: center;
                            justify-content: center;

                            @include mobile{
                                width: 20px;
                                height: 20px;
                            }
                            .imgPhone{
                                width: 25px;
                                margin: auto;
                                @include mobile{
                                    width: 15px;
                                }
                            }
                        }
                        h2{
                            font-size:25px;
                            @include mobile{
                                font-size:10px;
                            }
    
                        }
                        p{
                            font-size:15px;
                            @include mobile{
                                font-size:12px;
                            }
                        }
                        span{
                            font-size:12px;
                            font-weight: 600;
                            text-decoration: underline;
                            cursor: pointer;

                            @include mobile{
                                font-size:12px;
                                display: none;
                            }
                        }
                    }
                }
                .right{
                    flex: 8;
                    height: 100%;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    overflow:hidden;
                    
                    img{
                        width:400px;
                        transform :rotate(-10deg);
                    }
                }
            }
        }
    }

}