@import '../../global.scss';


.testimonials{
    background-color: white;
    display:flex;
    justify-content: center;
    flex-direction: column;
    
    @include mobile{
        justify-content:space-around;
    }

    h1{
        font-size:50px;
        display:flex;
        justify-content: center;

        @include mobile{
            font-size: 20px;
        }
    }
    .container{
        width:100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile{
            flex-direction: column;
        }
        .card{
            width: 300px;
            height: 75%;
            border-radius: 10px;
            margin: 0 30px;
            box-shadow: 0px 0px 15px -8px black;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding:20px;
            transition: all 1s ease;
            
            @include mobile{
                height:180px;
                margin: 10px 0;
            }
            // &.featured{
            //     width:300px;
            //     height: 75%;
            //     margin: 0 30px;

            //     @include mobile{
            //         width: 250px;
            //         height: 180px;
            //         margin: 1px;
            //     }
            // }

            &:hover{
                transform:scale(1.1);
            }
            .top{
                display: flex;
                align-items: center;
                justify-content: center;

                .left{
                    height:25px;
                }

                .right{
                    height:25px;
                }
                .user{
                    height: 120px;
                    width: 120px;
                    border-radius: 50%;
                    object-fit: cover;
                    margin: 0 30px;
                    
                    @include mobile{
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }

        .center{
            padding:15px;
            border-radius: 10px;
            background-color:#CAE4E5;

            @include mobile{
                font-size: 12px;
                padding:5px;
            }
        }
        .bottom{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            h3{
                margin-bottom: 5px;
                @include mobile{
                    font-size: 14px;

                }

            }
            h4{
                color: gray;
                @include mobile{
                    font-size: 13px;

                }
            }
        }
    }
}