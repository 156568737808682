@import '../../global.scss';
.story{
    background-color: white;
    display: flex;
    @include mobile{
        flex-direction: column;
    }
    .left{
        flex:5;
        display: flex;
        width: 100vh;
        height:100%;  
        justify-content: center;
        @include mobile{
            width:25%;
            height:10%;
            flex:2;
        }
        align-items: center;
        .code{
            font-size: 200px;
            font-weight: bold;
            @include mobile{
                font-size:50px;
            }
        }
    }
    .right{
        flex:8;
        display: flex;
        width: 100vh;
        height:100%; 
        align-items: center;
        // margin-top:150px; 
        @include mobile{
            align-items: flex-start;
            margin-left:15px ;

        }
        .title{
            font-size:50px;
            @include mobile{
                font-size:25px;
            }
        }
        .content{
            transition: all 5s ease;
            .featured{
                margin-top:20px;
                font-size:20px;
                display: flex;
                align-items: center;
                @include mobile{
                    font-size:12px;
                }
                .featuredIcon{
                    margin-right:20px;
                    @include mobile{
                        margin-right: 5px;
                    }
                }
            }
        }   
    }
}