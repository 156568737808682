@import '../../global.scss';
.experience{
    display: flex;
    flex-direction: column;
    background-color: white;
    .title{
        .titleFont{
            font-size: 50px;
            margin-left:100px;
            margin-top:50px;
            @include mobile{
                font-size:30px;
                margin-top:30px;
            };
        }
    }
    .content{
        display: flex;
        .contentLeft{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding:10px;
            flex:3;
            width:100vw;
            height:70vh;
            @include mobile{
                font-size:15px;
                flex:4;
            };
            .time{
                display: flex;
                justify-content: center;
                align-items: center;
                flex:3;
                font-size: 30px;
                @include mobile{
                    font-size:15px;
                    flex:2;
                };
            }
        }
        .contentCenter{
            flex:1;
            width:100%;
            padding:10px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include mobile{
                padding:0px;

            };
            .verticalLine{
                height: 75%;
                width: 1;
                background-Color: black;
            }
            img{
                height:100px;
                transform: rotate(90deg);
            }
        }
        .contentRight{
            flex:8;
            width:100%;
            display: flex;
            flex-direction: column;
            padding: 20px;
            font-size:20px;
            justify-content: center;
            @include mobile{
                padding:10px;
            };
            .wrapItem{
                display: flex;
                flex-direction: column;
                padding: 20px;
                justify-content: center;
                height: 100%;
                @include mobile{
                    padding:10px;
                    font-size:15px;
                };
                .item{
                    flex:5;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
            }
        }
    }
}