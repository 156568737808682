@import '../../global.scss';
.skill{
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    .title{
        flex:1;
        .titleFont{
            font-size: 50px;

            @include mobile{
                font-size:40px;
            }
        }
    }
    .content{
        flex:9;
        width:100%;
        height:100vh;
        display: flex;
        justify-content: center;
        @include mobile{
            flex-direction: column;
        }
        .left{
            flex:5;
            display: flex;
            justify-content: center;
            align-items: center;

            .chart{
                @include mobile{
                    width: 275px;
                    height: 150px;
                }
            }
        }
        .right{
            flex:8;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding:10px;
            @include mobile{
                flex:5;
            }
        }
    }
}