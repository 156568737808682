.aboutme{
    height:100vh;
    background-color: white;
    .sections{
        width:100%;
        height: calc(100vh - 70px);
        background-color: lightsalmon;
        position: relative;
        top:70px;
        --scroll-behavior: smooth;
        scroll-behavior: smooth !important;
        scroll-snap-type: y mandatory !important;
        scrollbar-width:none;//for firefox
        -webkit-transition: 190ms width linear; 
        &::-webkit-scrollbar{
            display: none;
        }

        > *{
            width:100vw;
            height: calc(100vh - 70px);
            scroll-snap-align: start;
        }
    }
} 