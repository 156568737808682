
@import '../../global.scss';
.product{
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @include mobile{
        flex-direction: column ;
    }
    .productLeft{
        flex:5;
        display: flex;
        width: 100vh;
        height:100%;
        justify-content: center;
        align-items: center;
        @include mobile{
            align-items: center;
        }
        .productIntro{
            display: flex;
            flex-direction: column;
            
            @include mobile{
                justify-content: center;
            }
        }
        .title{        
            font-weight: bold;
            font-size:70px;
            @include mobile{
                font-size:30px ;
                margin-left:25%;
            }
        }
        .desc{
            @include mobile{
                display: flex;
                justify-content: flex-start;
                margin-left:10px;
                margin-top:2%;
                font-size:12px ;
            }
        }
        .featured{
            display: flex;
            align-items: center;
            margin-top: 60px;
            margin-left:20px;
            font-size:30px;
            @include mobile{
                font-size:15px ;
                margin-top:5%;
                margin-left:25%;
            }
            // animation: fadein;
            // animation-duration:4s;
            .featuredIcon{
                margin-right: 20px;
                font-size: 2rem;
                @include mobile{
                    font-size: 1rem;
                }
            }

            // @keyframes fadein {
            //     0%{
            //         opacity: 0;
            //     }
            //     50%{
            //         opacity: 0.5;
            //     }
            //     100%{
            //         opacity: 1;
            //     }
            // }
        }
        .featured1{
            display: flex;
            align-items: center;
            margin-top: 60px;
            margin-left:20px;
            font-size:30px;
            animation: fadein;
            animation-duration:6s;
            .featuredIcon{
                margin-right: 20px;
                font-size: 2rem;
            }

            @keyframes fadein {
                0%{
                    opacity: 0;
                }
                50%{
                    opacity: 0.5;
                }
                100%{
                    opacity: 1;
                }
            }
        }
        .featured2{
            display: flex;
            align-items: center;
            margin-top: 60px;
            margin-left:20px;
            font-size:30px;
            animation: fadein;
            animation-duration:8s;
            .featuredIcon{
                margin-right: 20px;
                font-size: 2rem;
            }

            @keyframes fadein {
                0%{
                    opacity: 0;
                }
                50%{
                    opacity: 0.5;
                }
                100%{
                    opacity: 1;
                }
            }
        }
    }
    .productRight{
        flex:6;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100vh;
        height:100%;
        @include mobile{
            justify-content: center;
            
        }
        .item{
            width: 700px;
            height:100%;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;

            @include mobile{
                width:300px;
            }
            .desktopImg{
                position: absolute;
                top:0;
                left:0;
                z-index:1;
                width:800px;
                @include mobile{
                    width:320px;
                }
                
            }
            .productImg{
                position: absolute;
                top:75px;
                left:140px;
                z-index:2;
                width:450px; 
                overflow: hidden;
                // transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(50deg) rotateY(-10deg) rotateZ(10deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
                // transform: rotateX(120deg);
                // transform: rotateZ(150deg);
                // transform: rotateY(120deg);
                @include mobile{
                    top:-75px;
                    left:50px;
                    width: 200px;
                }
            }
            
        }

    }
}


