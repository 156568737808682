@import '../../global.scss';
.intro{
    background-color: white;
    display:flex;
    overflow: hidden;

    @include mobile{
        flex-direction: column;
        align-items: center;
    }
    .left{
        flex:0.5;
        overflow: hidden;
        .imgContainer{
            width:700px;
            height: 700px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            overflow: hidden;
            float:right;
            border-radius: 50%;
            @include mobile{
                border-radius: 20px;
            }
            img{
                object-fit: cover;
                @include mobile{
                    border-radius: 20px;
                    height: 100%;
                    width:60%;
                }
            }
        }
    }
    .right{
        flex:0.5;
        position:relative;
        .wrapper{
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mobile{
                padding-left: 0;
                align-items: center;
            }
            h1{
                font-size:60px;
                margin:10px 0;

                @include mobile{
                    font-size: 40px;
                }
            }
            h2{
                font-size:35px;

            }
            h3{
                font-size:30px;

                @include mobile{
                    font-style: 25px;
                }
                span{
                    font-size: inherit;
                    color:crimson;
                }
                .ityped-cursor {
                    animation: blink 1s infinite;
                }
                @keyframes blink {
                    50%{
                        opacity: 1;
                    }
                    100%{
                        opacity: 0;
                    }
                }
            }
            .aboutmeArea{
                .tome{
                    width: 50px;
                    .aboutme{
                        margin-top:20px;
                        width:50px;
                        height:50px;
                        cursor:pointer;
                    }
                    @include mobile{
                        display: flex;
                        margin-right: 200px;
                    }
                }
            }
           
        }
        .anchor{
            position: absolute;
            bottom: 10px;
            left:40%;

            .pulldown{
                font-size: 50px;
                animation: arrowBlink 2s infinite;
            }
            @keyframes arrowBlink {
                100%{
                    opacity: 0;
                }
            }
        }
    }
}